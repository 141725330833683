html {
  height: 100%;
}

body {
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.demo-editor .DraftEditor-root {
  height: auto;
}

.demo-wrapper {
  width: 100% !important;
  display: block !important;
}

.MuiFormHelperText-contained {
  margin: 8px 4px !important;
}

.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lds-dual-ring {
  text-align: center;
}

.ck.ck-editor__main>.ck-editor__editable {
  min-height: 120px;
}

.ck.ck-editor__main ul, .ck.ck-editor__main ol {
  margin-left: 15px;
}

.ck.ck-balloon-panel {
  z-index: 9999 !important;
}
